/*
 * @Autor: syq
 * @Date: 2021-07-12 16:37:32
 */

import request from "../../request/request";

// 获取前端类目树父节点集合
const queryTreeNode = async () =>
  request({
    url: `/frontCategory/getTree`,
    method: "get",
  });

// 根据父节点id查询整棵树
const queryTreeList = async (id) =>
  request({
    url: `/frontCategory/treeList/${id}`,
    method: "get",
  });

// 根据父节点id查询所有子树和关联结点
const queryTreeAllNode = async (id) =>
  request({
    url: `/frontCategory/treeAllNode/${id}`,
    method: "get",
  });

export { queryTreeNode, queryTreeList, queryTreeAllNode };
