<!--
 * @Autor: syq
 * @Date: 2021-07-10 17:53:04
-->
<template>
  <div class="commodity-cart">
    <div
      class="commodity-cart-item"
      v-for="ites in categoryList"
      :key="ites.id"
    >
      <!-- 标题 -->
      <div class="commodity-cart-item-title">{{ ites.name }}</div>
      <!-- 图片 -->
      <van-image :src="findUrl(ites)" />
      <!-- 分类 -->
      <van-grid :column-num="3" :border="false">
        <van-grid-item
          v-for="ites in ites.children"
          :key="ites.id"
          :icon="findIcon(ites)"
          :text="ites.name"
          @click="onClickNavigate(ites.id, ites.name)"
        />
      </van-grid>
    </div>
  </div>
</template>
<script>
import { trackEvent } from '../../../utils/utils'

export default {
  props: { categoryList: Array },
  data() {
    return {
      iconurl: [],
    };
  },
  methods: {
    onClickNavigate(themId, name) {
      trackEvent(null, '点击商品类目', name + themId)
      this.$router.push(`/theme?themId=${themId}`);
    },
    findUrl(item) {
      if (item.logoUrl !== "string") {
        return JSON.parse(item?.logoUrl)[0];
      } else {
        return "";
      }
    },
    findIcon(item) {
      if (item.logoUrl !== "string") {
        return JSON.parse(item.logoUrl)[0];
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.commodity-cart {
  width: 100%;
  padding-top: 0;
  // border-radius: 8px;
  .commodity-cart-item {
    background-color: #fff;
    border-radius: 8px;
    padding: 0 8px;
    margin: 8px 0;
    .commodity-cart-item-title {
      padding: 12px 0;
      font-size: 14px;
      font-weight: 600;
      color: @Titlecolor;
    }
    /deep/ .van-image {
      width: 100%;
      height: 64px;
      border: 1px solid transparent;
      .van-image__img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
    /deep/ .van-grid-item__content {
      // padding: 8px;
      padding: 8px 0;
      .van-grid-item__icon {
        font-size: 74px;
      }
      .van-grid-item__text {
        font-size: 12px;
      }
    }
  }

  /deep/ .van-sidebar-item__text {
    text-align: center;
  }
}
</style>
