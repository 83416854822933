<!--
 * @Autor: syq
 * @Date: 2021-07-09 19:45:45
-->
<template>
  <div id="classification">
    <div class="search">
      <van-button
        round
        size="small"
        block
        :icon="sousuo"
        type="default"
        :to="{path:'/searchbox',query:{type:'goodsSearch'}}"
      >
        <span @click="trackEventInner">请输入关键词进行搜索</span>
      </van-button>
    </div>
    <div class="main">
      <div class="main-content">
        <div class="left">
          <van-sidebar v-model="activeKey">
            <van-sidebar-item
              v-for="item in Sidebardata"
              :key="item.id"
              :title="item.name"
              @click="changeFirstLevelNode(item.id)"
            />
          </van-sidebar>
        </div>
        <div class="right">
          <commodity-cart :categoryList="categoryList" />
        </div>
      </div>
    </div>
    <van-loading size="24px" v-show="classfication" vertical
      >加载中...</van-loading
    >
  </div>
</template>
<script>
import CommodityCart from "./components/commodityCart.vue";
import { queryTreeNode } from "./service";
import sousuo from "../../assets/icon/souuso_icon@2x.png";
import { trackEvent } from '../../utils/utils'

import { Toast } from "vant";
export default {
  data() {
    return {
      sousuo,
      defaultId: "",
      classfication: false,
      activeKey: 0,
      treeNode: null,
      Sidebardata: [],
      categoryList: [],
    };
  },
  methods: {
    trackEventInner(eventName) {
      trackEvent(null, '点击搜索栏')
    },
    getTreeNode() {
      this.classfication = true;
      queryTreeNode()
        .then((response) => {
          if (response.status === "0") {
            this.classfication = false;
            this.Sidebardata = response.data;
            this.defaultId = response.data[0].id;
            this.changeFirstLevelNode(this.defaultId);
          } else {
            this.classfication = false;
            Toast.fail(response.msg);
          }
        })
        .catch((err) => {
          this.classfication = false;
          return err;
        });
    },
    changeFirstLevelNode(id) {
      let secondAndMoreLevel = this.Sidebardata.filter(
        (item) => item.id === id
      );
      this.categoryList =
        (secondAndMoreLevel[0]?.children &&
          secondAndMoreLevel[0]?.children?.map((i) => ({
            ...i,
          }))) ||
        [];
    },
  },
  components: { CommodityCart },
  mounted() {
    this.getTreeNode();
  },
};
</script>
<style lang="less" scoped>
#classification {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: @BackgroundColor;
  /deep/ .search {
    width: 100%;
    height: 54px;
    padding: 5px 12px;
    display: flex;
    align-items: center;
    background-image: @GradientColor;
    .van-button__text {
      color: #96979b;
    }
    .van-button--small {
      height: 32px;
    }
  }
  .main {
    width: 100%;
    flex:1 1 0;
    height: 100%;
    overflow: auto;
    .main-content {
      width: 100%;
      height: 100%; 
      display: flex;
      .left {
        width: 104px;
        height: 100%;
        border-right: 1px solid #ffffff;
        background: #ffffff;
        /deep/ .van-sidebar {
          width: 100%;
          height: 100%;
          .van-sidebar-item {
            background: #ffffff;
            padding: 12px;
            .van-sidebar-item__text {
              width: 100%;
              height: 20px;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: center;
            }
          }
          .van-sidebar-item--select::before {
            background-color: @Prompt;
            top: 44%;
          }
          .van-sidebar-item--select {
            color: #0095da;
          }
        }
      }
      .right {
        flex:1;
        height:100%;
        padding: 0 8px;
        background: #f5f6f7;
        overflow: auto;
      }
    }
  }

  /deep/ .van-icon__image {
    border-radius: 4px;
  }

  /deep/ .van-button--default {
    border: none;
  }
}
</style>
